import React from 'react';
import Layout from '../layouts/Layout';
import menu from '../data/json/menu.json';
import { Category, Item } from '../types/menu';

function formatPrice(price: number) {
  return `£${price.toFixed(2)}`;
}

function ItemRow({ item }: { item: Item }) {
  return (
    <div className="flex justify-between mb-1.5">
      <div>{item.name}</div>
      <div className="font-semibold text-blue">{formatPrice(item.price)}</div>
    </div>
  );
}

function CategorySection({ category }: { category: Category }) {
  const items = category.items.map((item) => <ItemRow key={item.name} item={item} />);
  return (
    <div className="mb-6">
      <h4 className="text-center text-blue font-bold text-lg mb-3">{category.name}</h4>
      {items}
    </div>
  );
}

export default function OurMenuPage() {
  const categoryData: Category[] = menu.categories;
  const categories = categoryData.map((category: Category) => <CategorySection key={category.name} category={category} />);
  const col1 = categories.slice(0, 4);
  const col2 = categories.slice(4, 8);
  const col3 = categories.slice(8, 11);

  return (
    <Layout>
      <title>Vicarage Road Chippy | Morriston, Swansea | Our Menu</title>
      <div className="flex flex-col md:flex-row md:-mx-4">
        <div className="md:w-1/3 md:px-4">{col1}</div>
        <div className="md:w-1/3 md:px-4">{col2}</div>
        <div className="md:w-1/3 md:px-4">{col3}</div>
      </div>
    </Layout>
  );
}
